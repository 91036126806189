// extracted by mini-css-extract-plugin
export var active = "booking-styes-module--active--faf7e";
export var arrow = "booking-styes-module--arrow--be450";
export var arrowDisabled = "booking-styes-module--arrow--disabled--c8c35";
export var arrowLeft = "booking-styes-module--arrow--left--23300";
export var arrowRight = "booking-styes-module--arrow--right--79470";
export var big = "booking-styes-module--big--bc655";
export var blink = "booking-styes-module--blink--39e38";
export var bookingBtn = "booking-styes-module--booking-btn--59e7d";
export var bookingPageHeader = "booking-styes-module--booking-page-header--ecdbb";
export var booking__alert = "booking-styes-module--booking__alert--a5a33";
export var booking__aside = "booking-styes-module--booking__aside--9961c";
export var booking__aside__book = "booking-styes-module--booking__aside__book--affcf";
export var booking__aside__code = "booking-styes-module--booking__aside__code--27b0e";
export var booking__aside__notEnoughtRooms = "booking-styes-module--booking__aside__notEnoughtRooms--02c4f";
export var booking__aside__price = "booking-styes-module--booking__aside__price--3ad9b";
export var booking__aside__pricePromoCode = "booking-styes-module--booking__aside__pricePromoCode--dfc94";
export var booking__aside__prices = "booking-styes-module--booking__aside__prices--31fe8";
export var booking__aside__room = "booking-styes-module--booking__aside__room--3a80d";
export var booking__back = "booking-styes-module--booking__back--30ab1";
export var booking__box = "booking-styes-module--booking__box--a97a4";
export var booking__container = "booking-styes-module--booking__container--ee4d0";
export var booking__content = "booking-styes-module--booking__content--39a00";
export var booking__inputModal = "booking-styes-module--booking__inputModal--26370";
export var booking__map = "booking-styes-module--booking__map--2e20c";
export var booking__rooms__container = "booking-styes-module--booking__rooms__container--7164c";
export var booking__step = "booking-styes-module--booking__step--eb12d";
export var booking__step__active = "booking-styes-module--booking__step__active--19130";
export var booking__step__number = "booking-styes-module--booking__step__number--1393e";
export var booking__steps = "booking-styes-module--booking__steps--40bc0";
export var checkout = "booking-styes-module--checkout--d11e9";
export var commentsSlider = "booking-styes-module--commentsSlider--5bdf8";
export var description = "booking-styes-module--description--92a75";
export var dot = "booking-styes-module--dot--6e490";
export var dots = "booking-styes-module--dots--e0298";
export var equallyBound = "booking-styes-module--equallyBound--4bbc5";
export var fader = "booking-styes-module--fader--c527e";
export var fader__slide = "booking-styes-module--fader__slide--a36aa";
export var featuredSlider = "booking-styes-module--featuredSlider--e481a";
export var globalWrapper = "booking-styes-module--global-wrapper--d4758";
export var headerIcons = "booking-styes-module--header-icons--c8929";
export var homepage = "booking-styes-module--homepage--f093b";
export var homepageReviews = "booking-styes-module--homepage-reviews--7a9eb";
export var isOpenMenu = "booking-styes-module--is-open-menu--eab71";
export var keenSlider = "booking-styes-module--keen-slider--fc03a";
export var lineMove = "booking-styes-module--lineMove--935ba";
export var logo = "booking-styes-module--logo--36030";
export var navigationWrapper = "booking-styes-module--navigation-wrapper--4e4e4";
export var scrollNav = "booking-styes-module--scrollNav--68167";
export var scrollNavWrap = "booking-styes-module--scrollNavWrap--8dca7";
export var slickSlide = "booking-styes-module--slick-slide--07ed4";
export var startTxtArea = "booking-styes-module--startTxtArea--b0442";