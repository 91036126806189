import React, { useState, useEffect, useRef } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { CardRoom, InfoBooking } from './components'
import {
  MapLocation,
  PropertyInfo,
  Steps,
  Navbar,
  Spinner,
  Modal,
} from '../../components'
import * as styles from './booking-styes.module.scss'
import LeftArrow from '../../assets/icons/reflections-group-icon-left-arrow.svg'
import ClosedIcon from '../../assets/icons/icon-close-open.svg'
import Seo from '../../components/Seo'

const BookingView = ({
  loading,
  loadingProperty,
  hotelDetails,
  totalPerson,
  totalRooms,
  setTotalRooms,
  goToPayment,
  handlePromoCode,
  setHasEnoughSpace,
  maxGuests,
  rooms,
  setIsOpenCouponCard,
  isOpenCouponCard,
  promoCode,
  diffDays,
  noOfDays,
}) => {
  const { t } = useTranslation()
  const inputRef = useRef(null)
  const breakpoints = useBreakpoint()
  const [totalPrice, setTotalPrice] = useState(0)
  const [totalPriceAfterDiscount, setTotalPriceAfterDiscount] = useState(0)
  const [enoughSpace, setEnoughSpace] = useState(true)
  const [enoughtRooms, setEnoughtRooms] = useState(false)
  const [totalCapacitySelected, setTotalCapacitySelected] = useState(0)

  const onHandleApplyCuppon = () => {
    setIsOpenCouponCard(false)
    handlePromoCode(inputRef.current.value)
  }

  const handelChangeNumberRooms = (obj, roomInfo) => {
    let restRoom = totalRooms
    const result = {
      roomId: roomInfo.roomTypeID,
      roomInfo,
      roomPrice: roomInfo.roomRate,
      quantity: obj.value,
    }
    if (_.some(totalRooms, { roomId: roomInfo.roomTypeID })) {
      restRoom = totalRooms.filter((ro) => ro.roomId !== roomInfo.roomTypeID)
    }

    const final = _.compact(_.union(restRoom, [result]))
    setTotalRooms(final.filter((r) => r.quantity !== 0))
  }

  useEffect(() => {
	setTotalPriceAfterDiscount(0);
	setTotalPrice(0);
	setTotalCapacitySelected(0);
	setTotalRooms(0);
  }, [rooms, noOfDays, diffDays])

  // we set total price for the rooms
  useEffect(() => {
    const tpr = _.reduce(totalRooms, (result, value) => result + (value.roomPrice * value.quantity), 0)
    setTotalPriceAfterDiscount(tpr)
    let totalPriceRooms
    switch (true) {
      case _.isEmpty(promoCode):
        totalPriceRooms = tpr
        break
      case promoCode[0].derivedType === 'percentage':
        totalPriceRooms = tpr + ((tpr / 100) * promoCode[0].derivedValue)
        break
      case promoCode[0].derivedType === 'fixed':
        totalPriceRooms = tpr + promoCode[0].derivedValue
        break
      default:
        totalPriceRooms = tpr
        break
    }

    setTotalPrice(totalPriceRooms < 0 ? 0 : totalPriceRooms)
  }, [totalRooms, promoCode])

  // we check if there are enough space for the number or person (Adults and Children)
  useEffect(() => {
    const peopleSelect = _.reduce(totalRooms, (acc, curr) => acc + curr.quantity, 0) * 2
    setTotalCapacitySelected(peopleSelect)
    setEnoughtRooms(totalPerson <= peopleSelect)
  }, [totalPerson, totalRooms])

  // we check if there are enough space in whole hotell
  useEffect(() => {
    setEnoughSpace(totalPerson <= maxGuests)
  }, [totalPerson, maxGuests])

//   console.log(totalPerson)

console.log('DIFFDAYS', diffDays, noOfDays)

  return (
    <div className={styles.booking__container}>
      <Seo title="Kamo - Booking" />
      <Navbar
        title={t('booking.titleSection')}
        loading={loading || loadingProperty}
        t={t}
        visible
      />
      {loading || loadingProperty
        ? <Spinner loading={true} />
        : <div className={styles.booking__box}>
          { breakpoints.sm && (<>
			<div style={{
				position: 'fixed',
				top: '0',
				right: '20px',
				maxWidth: 'calc(100% - 168px)',
				width: '100%',
				zIndex: 9999
			}}>
				<InfoBooking t={t} suites={_.reduce(totalRooms, (result, value) => result + value.quantity, 0)} totalPersonIncase={totalPerson} nights={_.reduce(totalRooms, (result, value) => result + value.quantity, 0) > 0 ? diffDays : 0} diffDays={diffDays} />
			</div>
		  </>)}
		  <aside className={styles.booking__aside}>
              <div className={styles.booking__aside__prices}>
                <p className={styles.booking__aside__room}>{t('booking.aside.suites')} <span>{_.reduce(totalRooms, (result, value) => result + value.quantity, 0)}</span></p>
				<p className={styles.booking__aside__room}>{t('booking.aside.nights')} <span>{_.reduce(totalRooms, (result, value) => result + value.quantity, 0) > 0 ? diffDays : 0}</span></p>
                <p className={styles.booking__aside__price}>{t('booking.aside.total')} <span class="big" dangerouslySetInnerHTML={{ __html: t('booking.aside.price', { price: totalPrice }) }}></span></p>
              </div>
              {promoCode && <p className={styles.booking__aside__pricePromoCode}>{t('booking.aside.price', { price: totalPriceAfterDiscount })}</p> }
              <button disabled={_.isEmpty(totalRooms) || !enoughtRooms } onClick={goToPayment} className={styles.booking__aside__book}><span>{t('booking.buttons.book')}</span></button>
              <span style={{ display: 'none' }} className={styles.booking__aside__notEnoughtRooms}>{!enoughtRooms && t(
                'booking.noEnoughtRooms',
                {
                  room: totalRooms.length,
                  people: totalCapacitySelected,
                  guests: totalPerson,
                },
              )}
              </span>
            </aside>
          <Steps t={t} isFirst/>
          <div>
		  <Link to={'/'} className={styles.booking__back}>
            <p><LeftArrow width="25px"/><span>{t('booking.buttons.back')}</span></p>
          </Link>
		  </div>
          <div className={styles.booking__content}>
            <div className={styles.booking__rooms__container}>
              { (!enoughSpace && totalRooms.length > 0) && (
                <div className={styles.booking__alert}>
                  <span onClick={() => setHasEnoughSpace(true)}><ClosedIcon fill="#FFF" width="20"/></span>
                  <p> {t('booking.noEnoughtSpace', { maxGuests })}</p>
                </div>
              )}
              { rooms.length === 0
                ? (
                  <div style={{ minHeight: '300px' }}>
                    <h3>{t('booking.noResults.title')}</h3>
                    <p className={styles.description}>{t('booking.noResults.description')}</p>
                  </div>
                )
                : _.map(rooms, (room) => <CardRoom
                  onChangeNumberRooms={handelChangeNumberRooms}
                  diffDays={noOfDays}
                  key={room.roomTypeID}
                  t={t}
                  data={room}
                />)
              }
              <PropertyInfo t={t} data={hotelDetails} contact={true} />
              {/* <div className={styles.booking__map}>
                <MapLocation isHome={true}/>
              </div> */}
            </div>
            {/* <aside className={styles.booking__aside}>
              <div className={styles.booking__aside__prices}>
                <p className={styles.booking__aside__room}>{t('booking.aside.room', { rooms: _.reduce(totalRooms, (result, value) => result + value.quantity, 0) })}</p>
                <p className={styles.booking__aside__price}>{t('booking.aside.price', { price: totalPrice })}</p>
              </div>
              {promoCode && <p className={styles.booking__aside__pricePromoCode}>{t('booking.aside.price', { price: totalPriceAfterDiscount })}</p> }
              <button disabled={_.isEmpty(totalRooms) || !enoughtRooms } onClick={goToPayment} className={styles.booking__aside__book}><span>{t('booking.buttons.book')}</span></button>
              <span onClick={() => setIsOpenCouponCard(true)} className={styles.booking__aside__code}>{t('booking.buttons.promo')}</span>
              <span style={{ display: 'none' }} className={styles.booking__aside__notEnoughtRooms}>{!enoughtRooms && t(
                'booking.noEnoughtRooms',
                {
                  room: totalRooms.length,
                  people: totalCapacitySelected,
                  guests: totalPerson,
                },
              )}
              </span>
            </aside> */}

          </div>
          <div className={styles.booking__map}>
                <MapLocation isHome={true}/>
              </div>
        </div>
      }
      {isOpenCouponCard
        && <Modal
          open={isOpenCouponCard}
          title={t('booking.discount.title')}
          buttontext={t('booking.discount.button')}
          onClick={onHandleApplyCuppon}
          onClosed={() => setIsOpenCouponCard(false)}
        >

          <input className={styles.booking__inputModal} ref={inputRef} type="text" name="text"/>
        </Modal>}
    </div>

  )
}

export default BookingView
